import React, { Component } from 'react'
import Select from 'react-select'
import { DropDownArrow } from './icons'

const boatType = [
  { label: "Houseboat", value: 'houseboat' },
  { label: "Ski and Wake Boat", value: 'ski_wake_boat' },
  { label: "Fishing Boat", value: 'fishing_boat' },
  { label: "Day Boat", value: 'day_boat' },
  { label: "Jetski", value: 'jetski' },
]

const boatStatus = [
  { label: "For Sale", value: 'for_sale' },
  { label: "Sold", value: 'sold' },
  { label: "Under Offer", value: 'under_offer' },
]

const priceMin = [
  { label: "$0", value: 0},
  { label: "$100,000", value: 100000},
  { label: "$200,000", value: 200000},
  { label: "$300,000", value: 300000},
  { label: "$400,000", value: 400000},
  { label: "$500,000", value: 500000},
  { label: "$600,000", value: 600000},
  { label: "$700,000", value: 700000},
  { label: "$800,000", value: 800000},
  { label: "$900,000", value: 900000},
  { label: "$1,000,000", value: 1000000},
]
const priceMax = [
  { label: "$100,000", value: 100000},
  { label: "$200,000", value: 200000},
  { label: "$300,000", value: 300000},
  { label: "$400,000", value: 400000},
  { label: "$500,000", value: 500000},
  { label: "$600,000", value: 600000},
  { label: "$700,000", value: 700000},
  { label: "$800,000", value: 800000},
  { label: "$900,000", value: 900000},
  { label: "$1,000,000", value: 1000000},
  { label: "$1,100,000", value: 1100000},
  { label: "$1,200,000", value: 1200000},
  { label: "$1,300,000", value: 1300000},
  { label: "$1,400,000", value: 1400000},
  { label: "$1,500,000+", value: 5000000},
]

class SearchForm extends Component {

  state = {
    boat_status: null,
    boat_type: null,
    price_min: null,
    price_max: null,
  }

  componentDidMount() {
    if ( window.location?.search !== '') {
      let state = this.state
      let queryStr = window.location?.search,
        queryArr = queryStr.replace('?','').split('&'),
        queryParams = []

      for (let i = 0; i < queryArr.length; i++) {
        let qArr = queryArr[i].split('=')
        if (qArr !== 'boat_type' || qArr !== 'boat_status' || qArr !== 'price_min' || qArr !== 'price_max') continue
        if (qArr[1] === '' || qArr[0] === 'gt') continue
        queryParams.push({
          name: qArr[0],
          value: qArr[1],
        })
        let arr = []
        if (qArr[0] === 'boat_type' ) arr = boatType
        if (qArr[0] === 'boat_status' ) arr = boatStatus
        if (qArr[0] === 'price_min' ) arr = priceMin
        if (qArr[0] === 'price_max' ) arr = priceMax
        let obj = {}
        if ( qArr[0] === 'price_min' || qArr[0] === 'price_max' ) {
          obj = arr.find(o => o.value === parseInt(qArr[1]));
        } else {
          obj = arr.find(o => o.value === decodeURIComponent(qArr[1]));
        }
        let option = {
          currentTarget: obj
        }
        state[qArr[0]] = obj
        this.handleChange(option)
      }
      this.setState({state})
    }
  }

  submitSearch = e => {
    const { home } = this.props
    if ( home ) return
    e.preventDefault()
  }

  handleChange = (option, field) => {
    //console.log(option, field)
    let state = {}
    switch (field && field.action) {
      case 'select-option':
        state[field.name] = option
        this.setState(state, this.updateList)
        break
      default:
        let { name, value } = option.currentTarget
        state[name] = value
        this.setState(state, this.updateList)
        break
    }

  }

  updateList = () => {
    const { list, set, home } = this.props
    const { boat_status, boat_type, price_min, price_max } = this.state

    if ( home ) return

    let results = {}

    if (list.nodes) {
      results.nodes = list.nodes.filter((el, i) => {
        const { acf } = el
        if (boat_status && (acf.boatStatus !== boat_status.value)) return false
        if (boat_type && (acf.boatType !== boat_type.value)) return false
        if (price_min && (parseInt(acf.price.searchPrice) < price_min.value)) return false
        if (price_max && (parseInt(acf.price.searchPrice) > price_max.value)) return false
        return true
      })
    }
    set(results)
  }

  render() {
    let { price_min, price_max, boat_status, boat_type } = this.state
    return (
      <section className="search">
        <div className="search__inner">
          <form className="search__form form" action='/buy/' onSubmit={this.submitSearch}>
            <div className="form__row form__row--dropdowns">
              <div className='form__select form__select--status'>
                <Select
                  options={boatStatus}
                  onChange={this.handleChange}
                  openMenuOnFocus={true}
                  id="boat_status"
                  name="boat_status"
                  value={boat_status}
                  placeholder="Boat Status"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  ref={node => this.boat_status = node}
                />
              </div>
              <div className='form__select form__select--type'>
                <Select
                  options={boatType}
                  onChange={this.handleChange}
                  openMenuOnFocus={true}
                  id="boat_type"
                  name="boat_type"
                  value={boat_type}
                  placeholder="Boat Type"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  ref={node => this.boat_type = node}
                />
              </div>
              <div className='form__select form__select--min'>
                <Select
                  options={priceMin}
                  onChange={this.handleChange}
                  openMenuOnFocus={true}
                  id="price_min"
                  name="price_min"
                  value={price_min}
                  placeholder="Min Price"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  ref={node => this.min = node}
                />
              </div>
              <div className='form__select form__select--max'>
                <Select
                  options={priceMax}
                  onChange={this.handleChange}
                  openMenuOnFocus={true}
                  id="price_max"
                  name="price_max"
                  value={price_max}
                  placeholder="Max Price"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  ref={node => this.max = node}
                />
              </div>
              <button className='form__submit' type='submit'>
                <DropDownArrow color={`#000000`} />
              </button>
            </div>
          </form>
        </div>
      </section>
    )
  }
}

export default SearchForm
