import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../../utils/link'
import { Area, Beds, Baths } from '../icons'

class BoatCard extends Component {
  render() {
    const { title, slug, featuredImage, acf } = this.props

    return (
      <div className='boat'>
        <Link to={`/boat/${slug}/`}>
          <div className='boat__image'>
            { featuredImage && <GatsbyImage image={getImage(featuredImage.node.localFile)} alt={title} /> }
            { !this.props.feature && <span className='boat__btn'>View more</span> }
            { this.props.feature &&
              <div className='boat__feature'>
                <h4>{title}</h4>
                <span>{acf.price.displayPrice}</span>
                <span className='btn'>View Details</span>
              </div>
            }
          </div>
          <div className='boat__content'>
            <div className='boat__title'>
              <h4>{title}</h4>
              <span>{acf.price.displayPrice}</span>
            </div>
            <div className='boat__meta'>
              <ul>
                <li>
                  <Area color={'#000000'} />
                  <span>{acf.specs.size}</span>
                </li>
                <li>
                  <Beds color={'#000000'} />
                  <span>{acf.specs.beds}</span>
                </li>
                <li>
                  <Baths color={'#000000'} />
                  <span>{acf.specs.baths}</span>
                </li>
              </ul>
            </div>
            <div className='boat__info' dangerouslySetInnerHTML={{ __html: acf.excerpt }} />
          </div>
        </Link>
      </div>
    )
  }
}

export default BoatCard
